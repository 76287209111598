import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './updatePaper.css'; // Import CSS file

const PaperList = () => {
    const [papers, setPapers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // For details modal
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false); // For assign reviewers modal
    const [reviewers, setReviewers] = useState([]); // State to store reviewers
    const [primaryReviewer, setPrimaryReviewer] = useState(''); // For primary reviewer
    const [secondaryReviewer, setSecondaryReviewer] = useState(''); // For secondary reviewer
    const [tertiaryReviewer, setTertiaryReviewer] = useState(''); // For tertiary reviewer

    useEffect(() => {
        // Fetch all papers including their acceptance status
        axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/submit/get-papers`)
            .then(response => setPapers(response.data.data))
            .catch(error => setError('Failed to fetch papers. Please try again later.'))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        // Fetch reviewers from backend
        axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/get-reviewers`)
            .then(response => {
                setReviewers(response.data.data || []);
            })
            .catch(error => {
                console.error('Error fetching reviewers:', error);
                setError('Failed to fetch reviewers. Please try again.');
            });
    }, []);

    const handleDetails = (paperId) => {
        const paper = papers.find(p => p._id === paperId);
        setSelectedPaper(paper);
        setIsModalOpen(true);
    };

    const handleAssignReviewer = (paperId) => {
        const paper = papers.find(p => p._id === paperId);
        setSelectedPaper(paper);
        setIsAssignModalOpen(true);
    };

    const handleAssignReviewers = async () => {
        if (!primaryReviewer && !secondaryReviewer && !tertiaryReviewer) {
            Swal.fire('Error', 'Please select at least one reviewer.', 'error');
            return;
        }
    
        // Create an array of reviewer objects with both name and email
        const reviewers = [primaryReviewer, secondaryReviewer, tertiaryReviewer]
            .filter(reviewer => reviewer)
            .map(reviewer => {
                const [firstName, lastName, email] = reviewer.split('|'); // Split value into first name, last name, and email
                return { name: `${firstName} ${lastName}`, email };
            });
    
        try {
            // Make PATCH API call to update reviewers and status
            await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/submit/assign-reviewers`, {
                paperId: selectedPaper._id,
                reviewers
            });
    
            Swal.fire('Success', 'Reviewers assigned and status updated to "Under Review".', 'success');
            setIsAssignModalOpen(false); // Close the assign modal
        } catch (error) {
            console.error('Error assigning reviewers:', error);
            Swal.fire('Error', 'Failed to assign reviewers. Please try again.', 'error');
        }
    };
    
    
    const handleAccept = async (paperId, index) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to accept this paper?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, accept it!',
            cancelButtonText: 'Cancel'
        });
    
        if (result.isConfirmed) {
            try {
                await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/submit/accept-paper`, { paperId });
                // Update the papers state to reflect the acceptance status
                setPapers(prevPapers =>
                    prevPapers.map((paper, i) => 
                        i === index ? { ...paper, accepted: true } : paper
                    )
                );
                Swal.fire('Accepted!', 'The paper has been accepted.', 'success');
            } catch (error) {
                console.error('Error accepting paper:', error);
                setError('Failed to accept the paper. Please try again.');
            }
        }
    };

    const handleUnaccept = async (paperId, index) => {
        try {
            await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/submit/unaccept-paper`, { paperId });
            // Update the papers state to reflect the unacceptance status
            setPapers(prevPapers =>
                prevPapers.map((paper, i) => 
                    i === index ? { ...paper, accepted: false } : paper
                )
            );
        } catch (error) {
            console.error('Error unaccepting paper:', error);
            setError('Failed to unaccept the paper. Please try again.');
        }
    };

    const handleDelete = async (paperId, index) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await axios.delete(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/submit/delete-paper`, { data: { paperId } });
                // Remove the deleted paper from the state
                setPapers(prevPapers => prevPapers.filter((paper, i) => i !== index));

                Swal.fire(
                    'Deleted!',
                    'The paper has been deleted.',
                    'success'
                );
            } catch (error) {
                console.error('Error deleting paper:', error);
                setError('Failed to delete the paper. Please try again.');
            }
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsAssignModalOpen(false);
        // Reset reviewer selections
        setPrimaryReviewer('');
        setSecondaryReviewer('');
        setTertiaryReviewer('');
    };
    const handleReject = async (paperId, index) => {
        try {
            await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/reject-paper`, { paperId });
            // Update the papers state to reflect the rejection status
            setPapers(prevPapers =>
                prevPapers.map((paper, i) =>
                    i === index ? { ...paper, status: 'Rejected' } : paper
                )
            );
        } catch (error) {
            console.error('Error rejecting paper:', error);
            setError('Failed to reject the paper. Please try again.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="paper-list">
            <h1>All Papers</h1>
            <ul>
                {papers.map((paper, index) => (
                    <li key={paper._id} className="paper-item">
                        <h2>{paper.title}</h2>
                        <button className="details-button" onClick={() => handleDetails(paper._id)}>Details</button>
                        <button className="assign-button" onClick={() => handleAssignReviewer(paper._id)}>Assign Reviewers</button>
                        {!paper.accepted && (
                            <button
                                className="accept-button"
                                onClick={() => handleAccept(paper._id, index)}
                            >
                                Paper Accept
                            </button>
                        )}
                        {paper.accepted && (
                            <>
                                <button
                                    className="accept-button accepted"
                                    disabled
                                >
                                    Accepted <span className="checkmark">&#10003;</span>
                                </button>
                                <button
                                    className="unaccept-button"
                                    onClick={() => handleUnaccept(paper._id, index)}
                                >
                                    Undo
                                </button>
                            </>
                        )}


{paper.status !== 'Rejected' && (
    <button
        className="reject-button"
        onClick={() => handleReject(paper._id, index)}
    >
        Reject
    </button>
)}
{paper.status === 'Rejected' && (
    <button
        className="rejected-button"
        disabled
    >
        Rejected
    </button>
)}



                        <button
                            className="delete-button"
                            onClick={() => handleDelete(paper._id, index)}
                        >
                            Delete
                        </button>
                    </li>
                ))}
            </ul>

            {/* Modal for paper details */}
            {isModalOpen && selectedPaper && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <h2>Title: {selectedPaper.title}</h2>
                        <p><strong>Abstract:</strong> {selectedPaper.abstract}</p>
                        <p><strong>Keywords:</strong> {selectedPaper.keywords}</p>
                        <p><strong>Paper Domains:</strong> {selectedPaper.paperDomains.join(', ')}</p>
                        <p><strong>Paper Link:</strong> <a href={selectedPaper.cloudinaryURL} target="_blank" rel="noopener noreferrer">View Paper</a></p>
                        <h3>Authors:</h3>
                        <ul>
                            {selectedPaper.author.map(author => (
                                <li key={author._id}>
                                    {author.firstName} {author.lastName} ({author.email})
                                </li>
                            ))}
                        </ul>
                        <button className="close-modal" onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}

       {/* Modal for assigning reviewers */}
{isAssignModalOpen && selectedPaper && (
    <div className="modal-overlay" onClick={closeModal}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>Assign Reviewers for: {selectedPaper.title}</h2>

            <div className="dropdown-select">
                <label htmlFor="primaryReviewer">Select Reviewer 1:</label>
                <select
                    id="primaryReviewer"
                    value={primaryReviewer}
                    onChange={e => setPrimaryReviewer(e.target.value)}
                >
                    <option value="">Select Reviewer 1</option>
                    {reviewers.map(reviewer => (
                        <option key={reviewer.email} value={`${reviewer.firstName}|${reviewer.lastName}|${reviewer.email}`}>
                            {reviewer.firstName} {reviewer.lastName} ({reviewer.email})
                        </option>
                    ))}
                </select>
            </div>

            <div className="dropdown-select">
                <label htmlFor="secondaryReviewer">Select Reviewer 2:</label>
                <select
                    id="secondaryReviewer"
                    value={secondaryReviewer}
                    onChange={e => setSecondaryReviewer(e.target.value)}
                >
                    <option value="">Select Reviewer 2</option>
                    {reviewers.map(reviewer => (
                        <option key={reviewer.email} value={`${reviewer.firstName}|${reviewer.lastName}|${reviewer.email}`}>
                            {reviewer.firstName} {reviewer.lastName} ({reviewer.email})
                        </option>
                    ))}
                </select>
            </div>

            <div className="dropdown-select">
                <label htmlFor="tertiaryReviewer">Select Reviewer 3:</label>
                <select
                    id="tertiaryReviewer"
                    value={tertiaryReviewer}
                    onChange={e => setTertiaryReviewer(e.target.value)}
                >
                    <option value="">Select Reviewer 3</option>
                    {reviewers.map(reviewer => (
                        <option key={reviewer.email} value={`${reviewer.firstName}|${reviewer.lastName}|${reviewer.email}`}>
                            {reviewer.firstName} {reviewer.lastName} ({reviewer.email})
                        </option>
                    ))}
                </select>
            </div>

            <button className="assign-button" onClick={handleAssignReviewers}>Assign</button>
            <button className="close-modal" onClick={closeModal}>Close</button>
        </div>
    </div>
)}


        </div>
    );
};

export default PaperList;
