import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './UnapprovedReviewersList.css';

const UnapprovedReviewersList = () => {
  const [unapprovedReviewers, setUnapprovedReviewers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUnapprovedReviewers();
  }, []);

  const fetchUnapprovedReviewers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/unapproved-reviewers`);
      setUnapprovedReviewers(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching unapproved reviewers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async (userId) => {
    try {
      console.log("Reviewer ID:", userId); // Log the reviewer ID for debugging
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/approve-reviewer`, { userId });
  
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Reviewer Accepted',
          text: 'The reviewer has been successfully accepted.',
        });
  
        setUnapprovedReviewers(prevReviewers =>
          prevReviewers.map(reviewer =>
            reviewer._id === userId ? { ...reviewer, isApproved: true } : reviewer
          )
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'Failed to accept reviewer.',
        });
      }
    } catch (error) {
      console.error('Error accepting reviewer:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <div className="container">
      <h2>Unapproved Reviewers</h2>
      <div className="reviewer-list">
        {loading ? (
          <p>Loading...</p>
        ) : (
          unapprovedReviewers.length > 0 ? (
            unapprovedReviewers.map((reviewer) => (
              <div key={reviewer._id} className="reviewer-card">
                <div>
                  <strong>Name:</strong> {`${reviewer.firstName} ${reviewer.lastName}`}
                </div>
                <div>
                  <strong>Email:</strong> {reviewer.email}
                </div>
              
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => handleAccept(reviewer._id)}
                    disabled={reviewer.isApproved} // Disable button if already approved
                  >
                    {reviewer.isApproved ? 'Accepted' : 'Accept'}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )
        )}
      </div>
    </div>
  );
};

export default UnapprovedReviewersList;
