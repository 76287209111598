import React from 'react';
import AdminComponent from '../../../Component/AdminComponent/AdminComponent';

const AdminHomePage = () => {
    return (
        <div>
            <AdminComponent />
        </div>
    );
};

export default AdminHomePage;