import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const RequestForReviewer = () => {
    const [requestedReviewerList, setRequestedReviewerList] = useState([]);
    const [displayName, setDisplayName] = useState('');
    const [loggedInEmail, setLoggedInEmail] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [reviewerRequestStatus, setReviewerRequestStatus] = useState(null);

    useEffect(() => {
        const email = localStorage.getItem('email');
        const displayName = localStorage.getItem('displayName');

        setLoggedInEmail(email);
        setDisplayName(displayName);

        const checkApprovalStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/reviewer/check/${email}`);
                setIsApproved(response.data.isApproved);
                setReviewerRequestStatus(response.data.reviwerrequestStatus); // Update with the field from your response

                const requestSentStatus = localStorage.getItem('requestSent') === 'true';
                setRequestSent(requestSentStatus);
            } catch (error) {
                console.error('Error checking approval status:', error);
            }
        };

        checkApprovalStatus();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const previousRole = 'author';
        const newRole = 'reviewer';
        const email = form.email.value;
        const message = form.description.value;

        const contact = { email, previousRole, newRole, message };

        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/reviewer/newrequest`, contact);

            if (res.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Reviewer Request sent Successfully',
                    showConfirmButton: false,
                    timer: 1500,
                });
                form.reset();
                setRequestedReviewerList([...requestedReviewerList, { email }]);
                setRequestSent(true);
                localStorage.setItem('requestSent', 'true');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    };

    return (
        <div>
            <h2 className='text-center my-3'>Provide Your Pertinence</h2>
            {!isApproved && !requestSent && (
                <form onSubmit={handleSubmit} className="col-md-6 col-sm-12 col-xs-12 m-auto py-3 text-dark" name="contact">
                    <div className="mb-4">
                        <label htmlFor="inputName" className="form-label text-dark"><strong>Name :</strong></label>
                        <input
                            required
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                            disabled
                            value={displayName}
                        />
                    </div>
                    <div className="ml-auto mr-auto mb-4">
                        <label htmlFor="inputEmail" className="form-label text-dark"><strong>Email :</strong></label>
                        <input
                            required
                            name="email"
                            type="email"
                            className="form-control"
                            value={loggedInEmail}
                            disabled
                            placeholder="Your Email"
                        />
                    </div>
                    <div className="ml-auto mr-auto mb-2">
                        <label htmlFor="inputMessage" className="form-label text-dark"><strong>Your Message :</strong></label>
                        <textarea
                            required
                            name="description"
                            className="form-control"
                            id="inputMessage"
                            placeholder="Your Message"
                            style={{ height: "150px" }}
                        />
                    </div>
                    <div className="text-center">
                        <button className="btn btn-outline-info text-dark" type="submit">
                            Request
                        </button>
                    </div>
                </form>
            )}
            {requestSent && !isApproved && (
                <p className="text-center text-info">You have already sent a request to become a reviewer. Please wait for approval.</p>
            )}
            {isApproved && (
                <p className="text-center text-success">You are already a reviewer!</p>
            )}
            {reviewerRequestStatus === 'rejected' && (
                <p className="text-center text-danger">Your reviewer request was rejected.</p>
            )}
        </div>
    );
};

export default RequestForReviewer;
