import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import "./SignUp.css";
const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false); // Added loading
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: ''
  });
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1); // Step 1: Signup, Step 2: OTP Verification
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match',
      });
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/new-sign-up/sign-up`, formData);
      if (response.status === 200 && response.data.token) {
        setStep(2); // Proceed to OTP verification step
        // Store the token in state or local storage
        localStorage.setItem('signupToken', response.data.token);
  
        Swal.fire({
          icon: 'success',
          title: 'Complete your Signup by OTP',
          text: 'Please check your email for the OTP',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Signup Failed',
          text: response.data.message || 'Failed to sign up',
        });
      }
    } catch (error) {
      console.error('Error signing up:', error);
      Swal.fire({
        icon: 'error',
        title: 'Signup Error',
        text: error.response?.data?.message || 'Something went wrong. Please try again later.',
      });
    }
  };
  

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
  
    try {
      // Retrieve the token from local storage or state
      const token = localStorage.getItem('signupToken');
  
      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Verification Error',
          text: 'No token found. Please try signing up again.',
        });
        return;
      }
  
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/new-sign-up/verify-otp`, { otp, token });
    
      // Check if the verification was successful
      if (response.status === 200 && response.data.message === 'User verified successfully') {
        const user = response.data.user; // Assuming the user data is returned in the response
  
        if (user.role === 'reviewer' && !user.isApproved) {
          Swal.fire({
            icon: 'info',
            title: 'Approval Pending',
            text: 'Your account is pending approval by an admin.',
          });
          navigate('/');
        }
       else if (user.role === 'author' && user.isApproved) {
          Swal.fire({
            icon: 'success',
            title: 'Complete your Registration',
            text: 'You can login your account.',
          });
          navigate('/login');
        }
        else {
          Swal.fire({
            icon: 'success',
            title: 'OTP Verified',
            text: 'Your account has been successfully verified.',
          }).then(() => {
            // Clear the token after verification
            localStorage.removeItem('signupToken');
            navigate('/thank-you'); // Redirect to the thank you page
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Verification Failed',
          text: response.data.message || 'Failed to verify OTP. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      Swal.fire({
        icon: 'error',
        title: 'Verification Error',
        text: error.response?.data?.message || 'Something went wrong. Please try again later.',
      });
    }
  }
  

  return (
    <div className="signup-container">
    
        <h2>Sign Up</h2>
      {step === 1 ? (
      
        <form onSubmit={handleSignup}>
          <div>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          <select
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select a role</option>
            <option value="trackchair">Track Chair</option>
            <option value="reviewer">Reviewer</option>
            <option value="author">Author</option>
          </select>
          <button type="submit" className="signin-button" disabled={isLoading}>
                        {isLoading ? 'Signup in...' : 'SIGN UP'}
                    </button>
        </form>
      ) : (
        <form onSubmit={handleVerifyOtp}>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button type="submit">Verify OTP</button>
        </form>
      )}
    </div>
  );
};

export default SignUp;
