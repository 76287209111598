import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './RequestReviewers.css';

const RequestReviewers = () => {
  const [requestedReviewers, setRequestedReviewers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRequestedReviewers();
  }, []);

  const fetchRequestedReviewers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/requested-reviewers`);
      setRequestedReviewers(response.data);
    } catch (error) {
      console.error('Error fetching requested reviewers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/accept-request`, { userId });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Reviewer Accepted',
          text: 'The reviewer has been successfully accepted.',
        });

        setRequestedReviewers(prevReviewers =>
          prevReviewers.map(reviewer =>
            reviewer._id === userId ? { ...reviewer, status: 'Accepted' } : reviewer
          )
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'Failed to accept reviewer.',
        });
      }
    } catch (error) {
      console.error('Error accepting reviewer:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
      });
    }
  };

  const handleReject = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/v1/admin/reject-request`, { userId });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Reviewer Rejected',
          text: 'The reviewer request has been successfully rejected.',
        });

        setRequestedReviewers(prevReviewers =>
          prevReviewers.filter(reviewer => reviewer._id !== userId)
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'Failed to reject reviewer.',
        });
      }
    } catch (error) {
      console.error('Error rejecting reviewer:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <div className="container">
      <h2>Requested Reviewers</h2>
      <div className="reviewer-list">
        {loading ? (
          <p>Loading...</p>
        ) : (
          requestedReviewers.length > 0 ? (
            requestedReviewers.map((reviewer) => (
              <div key={reviewer._id} className="reviewer-card">
                <div>
                  <strong>Name:</strong> {`${reviewer.firstName} ${reviewer.lastName}`}
                </div>
                <div>
                  <strong>Email:</strong> {reviewer.email}
                </div>
                <div>
                  <strong>Message:</strong> {reviewer.message}
                </div>
                <div className="action-buttons">
                  <button
                    className="btn btn-success"
                    onClick={() => handleAccept(reviewer._id)}
                    disabled={reviewer.status === 'Accepted'}
                  >
                    {reviewer.status === 'Accepted' ? 'Accepted' : 'Accept'}
                  </button>
                  <button
                    className="btn btn-danger reject-button"
                    onClick={() => handleReject(reviewer._id)}
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )
        )}
      </div>
    </div>
  );
};

export default RequestReviewers;
